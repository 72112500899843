<template>
  <div>
    <b-flix-card>
      <b-flix-p class="flex flex-center">
        <b-flix-loader></b-flix-loader>
      </b-flix-p>
      <div class="process">
        <div class="status" :style="'width: ' + percent + '%'"></div>
      </div>
      <b-flix-p class="flex flex-center">
        <b class="flix-strong">
          {{
            $t('message.restore', {
              name: $tc('message.backup', 1)
            })
          }}: {{ percent }}%
        </b>
      </b-flix-p>
      <b-flix-p class="flex flex-center">
        <small>{{ $t('message.waitForProcess') }}</small>
      </b-flix-p>
    </b-flix-card>
  </div>
</template>
<script>
export default {
  name: 'backupProcessing',
  components: {},
  props: {},
  data() {
    return {
      percent: 0,
      interval: false
    }
  },
  computed: {},
  mounted() {
    this.setProcess()
  },
  methods: {
    setProcess() {
      const seconds = 1000 * 60 * 2
      this.interval = setInterval(
        () => (this.percent = this.percent + 1),
        seconds / 100
      )

      setTimeout(() => {
        clearInterval(this.interval)
        this.$router.push({ name: 'dashboardLogout' })
      }, seconds)
    }
  }
}
</script>
<style lang="sass" scoped>
.process
  position: relative
  width: 100%
  display: block
  height: 30px
  background-color: lightgray
  .status
    transition: all 1.1s linear
    position: absolutes
    top: 0
    left: 0
    height: 30px
    background-color: #42bd84
</style>
